import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import Layout from "../components/common/Layout2";
import SEO from "../components/common/Seo";
// import Img from "react-cloudinary-lazy-image";
// import tw from "twin.macro";

const GDPR = () => {
    const intl = useIntl();
    return (
        <>
            <Layout>
                <SEO
                    title={intl.formatMessage({ id: "8" })}
                    description={intl.formatMessage({ id: "5" })}
                />             
            </Layout>
        </>
    );
};
export default GDPR;