import React from "react";
import Img from "react-cloudinary-lazy-image";
import tw from "twin.macro";
import { useIntl, changeLocale, Link } from "gatsby-plugin-intl";

const Layout2 = ({ children }) => {
    const intl = useIntl();

    const headerStyle = tw`mx-auto shadow-lg bg-gray-50 sticky top-0 z-50`;
    const footerStyle = tw`mx-auto shadow-lg bg-quaternary`;
    const mainStyle = tw`mx-auto bg-white`;

    return (
        <>
            { /** Header */}
            <header css={[headerStyle]}>
                <div css={[tw`max-w-7xl mx-auto py-5 px-5 xl:px-0`]}>
                    <div css={[tw`flex items-center justify-between`]}>
                        <div css={[tw`w-64 -ml-1`]}>
                            <Img
                                css={[tw`duration-300 mx-auto text-white`]}
                                cloudName="ajmontroig"
                                imageName={`web/logos/logo-text-icon_oay9u8`}
                                fluid={{
                                    maxWidth: 512,
                                }}
                                alt={"BDTMM"}
                            />
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </header>

            {/** Main */}
            <main css={[mainStyle]}>
                <div css={[tw`max-w-7xl mx-auto`]}>
                    {children}
                </div>
            </main>

            {/** Footer */}
            <footer css={[footerStyle]}>
                <div css={[tw`max-w-7xl mx-auto py-5 px-5 xl:px-0`]}>
                    <div
                        css={[
                            tw`lg:flex items-center justify-between font-montserrat text-xs font-semibold uppercase text-white opacity-80`,
                        ]}
                    >
                        <div css={[tw`text-center lg:text-left`]}>
                            Copyright &copy; {new Date().getFullYear()} -{" "}
                            {intl.formatMessage({ id: "3" })}
                            <button css={[tw`ml-5 duration-300 mr-2`, intl.locale === "es" ? tw`border-b` : null]} onClick={() => { changeLocale("es"); }}>ES</button>
                            |
                            <button css={[tw`duration-300 mx-2`, intl.locale === "ca" ? tw`border-b` : null]} onClick={() => { changeLocale("ca"); }}>CA</button>
                            |
                            <Link css={[tw`duration-300 hover:border-b ml-2`]} to="/gdpr/">{intl.formatMessage({ id: "8" })}</Link>
                        </div>

                        <div
                            css={[
                                tw`hidden lg:block text-center lg:text-right lg:flex lg:items-center`,
                            ]}
                        >
                            {intl.formatMessage({ id: "1" })}
                            <div css={[tw`inline w-5 h-5 ml-2`]}>
                                <Img
                                    css={[
                                        tw`duration-300 mx-auto text-white w-7 h-7`,
                                    ]}
                                    cloudName="ajmontroig"
                                    imageName={`web/logos/logo-rounded_vdme8m`}
                                    fluid={{
                                        maxWidth: 80,
                                    }}
                                    alt={"LOGO"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default Layout2;
